import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const appNavigationFeatureActions = createActionGroup({
  source: 'App Navigation Feature',
  events: {
    'Load Navigation Menu': emptyProps(),
    'Reset Navigation Search': emptyProps(),
    'Search Navigation Links': props<{ searchValue: string }>(),
    'Set Navigation Hidden': emptyProps(),
    'Set Navigation Opened': props<{ navigationOpened: boolean }>(),
    'Toggle Navigation Visibility': emptyProps(),
  },
});
