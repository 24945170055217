import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import { NavigationItem } from '@ciphr/core/app-navigation/models';

import { appNavigationDataAdapterActions } from './actions/app-navigation-data-adapter.actions';
import { appNavigationFeatureActions } from './actions/app-navigation-feature.actions';

export interface AppNavigationState {
  navigationHidden: boolean;
  navigationMenu: NavigationItem[];
  navigationOpened: boolean;
  navigationSearch: {
    loading: boolean;
    results: NavigationItem[];
    searchValue?: string;
  };
}

export const prepareAppNavigationInitialState = (): AppNavigationState => ({
  navigationHidden: true,
  navigationMenu: [],
  navigationOpened: false,
  navigationSearch: {
    loading: false,
    results: [],
  },
});

export const appNavigationFeature = createFeature({
  name: 'AppNavigation',
  reducer: createReducer(
    prepareAppNavigationInitialState(),
    immerOn(appNavigationDataAdapterActions.navigationMenuLoadedSuccessfully, (state, { navigationMenu }) => {
      state.navigationMenu = navigationMenu;
    }),
    immerOn(appNavigationDataAdapterActions.navigationLinksSearchedSuccessfully, (state, { results }) => {
      state.navigationSearch.loading = false;
      state.navigationSearch.results = results;
    }),
    immerOn(appNavigationDataAdapterActions.navigationLinksSearchingFailed, (state) => {
      state.navigationSearch.loading = false;
    }),
    immerOn(appNavigationFeatureActions.resetNavigationSearch, (state) => {
      state.navigationSearch = {
        loading: false,
        results: [],
      };
    }),
    immerOn(appNavigationFeatureActions.searchNavigationLinks, (state, { searchValue }) => {
      state.navigationSearch.loading = true;
      state.navigationSearch.searchValue = searchValue;
    }),
    immerOn(appNavigationFeatureActions.setNavigationHidden, (state) => {
      state.navigationHidden = true;
    }),
    immerOn(appNavigationFeatureActions.setNavigationOpened, (state, { navigationOpened }) => {
      state.navigationOpened = navigationOpened;
    }),
    immerOn(appNavigationFeatureActions.toggleNavigationVisibility, (state) => {
      state.navigationHidden = !state.navigationHidden;
    }),
  ),
});
